<template>
  <div id="map">
    <l-map ref="map" :zoom="8" :center="center" @update:zoom="updateZoom">
      <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <l-circle
        v-if="showCircle && geolocation"
        :lat-lng="geolocationLatLng"
        :radius="radius"
        :weight="2"
        color="#1332D6"
        fill-color="#1332D6"
      ></l-circle>
      <v-marker-cluster ref="cluster" :options="clusterOptions">
        <l-marker
          v-for="(site, i) in markers"
          :key="i"
          :lat-lng="site.latlng"
          :options="{ status: site.status }"
          @click="markerClick(site)"
        >
          <l-tooltip :options="{ direction: 'top' }">
            <strong>{{ site.name }}</strong>
            <br />
            {{ site.address.address_line }}<br />
            {{ site.address.zipcode }} {{ site.address.locality }}
          </l-tooltip>
          <l-icon
            :icon-url="siteIcon(site)"
            :icon-anchor="[16, 40]"
            :icon-size="[32, 40]"
            :tooltip-anchor="[0, -46]"
          ></l-icon>
        </l-marker>
      </v-marker-cluster>
      <l-marker v-if="geolocation" :lat-lng="geolocationLatLng">
        <l-icon
          :icon-url="`/img/marker-blue-small.png`"
          :icon-anchor="[8, 17]"
          :icon-size="[16, 20]"
        ></l-icon>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import $ from "jquery";
import L, { divIcon, latLng, point } from "leaflet";
import Vue from "vue";
import {
  LCircle,
  LIcon,
  LMap,
  LMarker,
  LTileLayer,
  LTooltip,
} from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import { mapActions, mapState } from "vuex";
import EventBus from "@/services/EventBus";

// Fix incorrect Leaflet icon reference
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    LCircle,
    LIcon,
    LMap,
    LMarker,
    LTileLayer,
    LTooltip,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      center: latLng(51.0317794, 3.6896891),
      radius: Vue.prototype.$geofencingRadius,
      boundsFit: false,
      showCircle: true,
    };
  },
  computed: {
    ...mapState({
      geolocation: (state) => state.app.geolocation,
      selectedSite: (state) => state.site.selectedSite,
      showMap: (state) => state.app.showMap,
      sites: (state) => state.site.sites,
    }),
    clusterOptions() {
      return {
        iconCreateFunction: (cluster) => {
          const markers = cluster.getAllChildMarkers();

          let status = "ok";
          if (markers.some((m) => m.options.status === "warning")) {
            status = "warning";
          }
          if (markers.some((m) => m.options.status === "serious")) {
            status = "serious";
          }
          if (markers.some((m) => m.options.status === "critical")) {
            status = "critical";
          }

          return divIcon({
            html: markers.length,
            className: `marker-cluster marker-cluster-${status}`,
            iconSize: point(40, 40),
          });
        },
        showCoverageOnHover: false,
      };
    },
    geolocationLatLng() {
      return latLng(this.geolocation.latitude, this.geolocation.longitude);
    },
    markers() {
      return this.sites
        .filter((site) => site.location.latitude && site.location.longitude)
        .map((site) => {
          let icon = "marker-green";
          if (site.status === "warning") {
            icon = "marker-yellow";
          }
          if (site.status === "serious") {
            icon = "marker-orange";
          }
          if (site.status === "critical") {
            icon = "marker-red";
          }

          return {
            ...site,
            icon,
            latlng: latLng(site.location.latitude, site.location.longitude),
          };
        });
    },
  },
  watch: {
    showMap(value) {
      if (!value) {
        return;
      }

      // Fix bug when opening the application on mobile with the map disabled and then toggling it
      this.initMap();
    },
    selectedSite(value) {
      if (value) {
        return;
      }

      // Fix bug when coming back to map from site details and scrolling
      this.initMap();
    },
    async sites() {
      await this.$nextTick();
      this.$refs.cluster.mapObject.refreshClusters();
    },
  },
  mounted() {
    this.initMap();

    EventBus.$off("focus-map");
    EventBus.$on("focus-map", (site) => {
      this.focusOnSite(site);
    });
  },
  methods: {
    ...mapActions(["toggleMap"]),
    updateZoom(value) {
      this.showCircle = value > 10;
    },
    siteIcon(site) {
      if (this.selectedSite && this.selectedSite.id === site.id) {
        return `/img/${site.icon}-selected.png`;
      }
      return `/img/${site.icon}.png`;
    },
    markerClick(site) {
      Vue.prototype.$navigate?.openNetworkSiteDetails(site.id);
    },
    async initMap() {
      // Cancel if map isn't visible
      if (!$("#map").is(":visible")) {
        return;
      }

      await this.$nextTick();

      this.$refs.map.mapObject.invalidateSize();
      this.fitBoundsToMarkers();
    },
    async fitBoundsToMarkers() {
      if (this.boundsFit || this.markers.length === 0) {
        return;
      }

      await this.$nextTick();

      this.$refs.map.mapObject.fitBounds(
        this.markers.map((m) => [m.latlng.lat, m.latlng.lng]),
        { padding: [10, 10] }
      );

      this.boundsFit = true;
    },
    async focusOnSite(site) {
      // Toggle map if it's not visible (on mobile)
      if (!$("#map").is(":visible")) {
        if (!this.showMap) {
          this.toggleMap();
        }

        this.$router.push({ name: "NetworkIndex" });
      }

      await this.$nextTick();

      if (!site.location) {
        console.error("Site has no geolocation", site);
        return;
      }

      this.$refs.map.mapObject.fitBounds(
        [[site.location.latitude, site.location.longitude]],
        { padding: [10, 10] }
      );
    },
  },
};
</script>
