<template>
  <div class="network">
    <v-switch v-model="showMapLocal" :label="$t('show_map')" class="toggle-map">
      <template v-slot:label>
        <v-icon>fa-map-marker-alt</v-icon>
      </template>
    </v-switch>
    <v-map></v-map>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Map from "../components/Network/Map";

export default {
  name: "Network",
  components: {
    "v-map": Map,
  },
  props: {
    siteId: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState({
      showMap: (state) => state.app.showMap,
    }),
    showMapLocal: {
      get() {
        return this.showMap;
      },
      set() {
        this.toggleMap();
      },
    },
  },
  methods: {
    ...mapActions(["toggleMap"]),
  },
};
</script>
